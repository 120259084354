/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Flex, Box, Text } from '@theme-ui/components'
import NavBar from '../components/navbar'
import '../components/layout.css'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import canadaFlag from '../images/canada-flag-waving-icon-32.png'
import colombiaFlag from '../images/colombia-flag-waving-icon-32.png'
import Footer from '../components/footer'
import PageLayout from '../components/page-layout.js'

const Profile = ({ name, job, image, children, flagSource, countryName, justify }) => {
  return (
    <Flex
      sx={{
        mt: 4,
        flexDirection: ['column', justify === 'left' ? 'row' : 'row-reverse', null],
        textAlign: ['left', justify, null],
      }}
    >
      <Box sx={{ width: ['100%', '33%', null], height: 'auto' }}>
        <Img alt={`photo of ${name}`} fluid={image.childImageSharp.fluid} />
      </Box>
      <Box
        sx={{
          mr: [0, justify === 'right' ? 4 : 0, null],
          ml: [0, justify === 'left' ? 4 : 0, null],
          width: ['100%', '67%', null],
          mt: [2, 0, null],
        }}
      >
        <h2 sx={{ fontSize: 4, pb: 0, my: 0, lineHeight: '1.25', display: 'inline' }}>{name}</h2>
        <img
          alt={`flag of ${countryName}`}
          sx={{ display: 'inline', ml: 2 }}
          src={flagSource}
        ></img>
        <Text sx={{ fontSize: 3, lineHeight: '1.25' }}>{job}</Text>
        {children}
      </Box>
    </Flex>
  )
}

export default function AboutPage({ data }) {
  return (
    <PageLayout title="About us" description="All about the Get Fluent Spanish team">
      <h1>About us</h1>

      <p>
        Get Fluent Spanish is the product of a 3-person team with a common goal: to make great
        content to help Spanish students break through the intermediate plateau and experience the
        rewards of true fluency.
      </p>

      <p>
        Becoming fluent is not easy, and as you advance, it can become very difficult to find
        resources to keep improving. We try to address the biggest problems that intermediate and
        advanced students face, and provide lessons, exercises and podcasts to help you to really
        master the language.
      </p>

      <Profile
        image={data.andrea}
        job="Spanish teacher, writer"
        name="Andrea"
        flagSource={colombiaFlag}
        countryName="Colombia"
        justify="left"
      >
        <p>
          Hello! I’m Andrea and I am a Spanish teacher. I’m originally from Colombia but I was
          raised in Venezuela, so I can say I’m colombo-venezolana. In university I studied Modern
          Languages, and there aren’t enough words -either in Spanish or English- to tell you how
          much I enjoyed it! Languages are my passion in life, along with Colombian coffee and
          Venezuelan arepas, of course.
        </p>
        <p>
          I’m an experienced and passionate teacher and am always cheerful simply because I love
          what I do! Along with professor Merly, I’m in charge of writing the content and making all
          the coffee we need for our long grammar discussions. I personally think that teaching a
          language is all about sharing your culture, feeling empathy, and having a lot of fun, and
          I really hope that you will see that reflected in our website.
        </p>
      </Profile>

      <Profile
        image={data.merly}
        job="Spanish teacher, writer"
        name="Merly"
        flagSource={colombiaFlag}
        countryName="Colombia"
        justify="right"
      >
        <p>
          Hi, I'm Merly, and I'm a Spanish teacher from Colombia! Teaching is truly my passion. In
          fact, I decided that I was going to become a teacher when I was just 8 years old! Since
          then, I have earned a degree in Spanish language education and have been teaching Spanish
          to both native speakers and foreigners for more than 10 years.
        </p>
        <p>
          I love challenging my students, taking them out of their comfort zone and showing them
          that they are capable of more than they believe they are. I also enjoy debating grammar
          with Andrea and finding the best way to explain important but seldom taught topics. My
          other passion is dancing (even when I'm explaining grammar, I can't stop dancing!), and I
          am convinced that learning a language is like learning to dance. For this reason, I want
          to help Spanish students not just learn the language but feel it, enjoy it and live it as
          well.
        </p>
      </Profile>

      <Profile
        image={data.michael}
        job="Developer, editor"
        name="Michael"
        flagSource={canadaFlag}
        countryName="Canada"
        justify="left"
      >
        <p>
          I'm Michael and I write the code and edit the content for the site. I am also obsessed
          with the Spanish language, having moved to Colombia from Canada in 2017 to live my life
          completely in Spanish. This is where I met Merly and Andrea, the other members of the
          team.
        </p>
        <p>
          For me, the greatest reward of learning Spanish is not completing a new Duolingo level,
          getting a school credit or impressing other English speakers at parties. It is being able
          to connect culturally with an enormous number of people from many countries, understanding
          and relating to them in a way that would be otherwise impossible. I hope that through the
          content of this website, we can help others push through the intermediate plateau and reap
          the rewards of real fluency.
        </p>
        <p>
          When not writing code or speaking Spanish, I like to travel, play chess, and do heavy
          barbell squats.
        </p>
      </Profile>
    </PageLayout>
  )
}

export const profileImage = graphql`
  fragment profileImage on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export const pagequery = graphql`
  query {
    andrea: file(relativePath: { eq: "andrea_circle.png" }) {
      ...profileImage
    }
    merly: file(relativePath: { eq: "merly_circle.png" }) {
      ...profileImage
    }
    michael: file(relativePath: { eq: "mike_circle.png" }) {
      ...profileImage
    }
  }
`
